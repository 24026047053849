import React from "react"

export default props => (
  <svg
    width="377"
    height="701"
    viewBox="0 0 377 701"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 603.354C7.762 603.354 10 605.585 10 608.338C10 611.091 7.762 613.322 5 613.322C2.238 613.322 -3.01749e-07 611.091 -3.01749e-07 608.338C-3.01749e-07 605.585 2.238 603.354 5 603.354"
      fill="#F1DEFA"
    />
    <path
      d="M175 680.611C180.524 680.611 185 685.073 185 690.58C185 696.087 180.524 700.549 175 700.549C169.476 700.549 165 696.087 165 690.58C165 685.073 169.476 680.611 175 680.611"
      fill="#FFDF37"
    />
    <path
      d="M42.2482 44.3289C37.9579 28.3856 47.4556 12.0055 63.4675 7.73352C79.4794 3.46153 95.93 12.9186 100.22 28.8619C104.511 44.8052 95.013 61.1853 79.0011 65.4573C62.9892 69.7293 46.5386 60.2722 42.2482 44.3289"
      fill="#FFDF37"
    />

    <path
      opacity="0.5"
      d="M269.727 510.904C250.08 491.319 250.08 459.58 269.727 439.995C289.373 420.41 321.211 420.41 340.857 439.995C360.504 459.58 360.504 491.319 340.857 510.904C321.211 530.489 289.373 530.489 269.727 510.904"
      fill="#73FFF7"
    />
  </svg>
)
